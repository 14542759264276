// Generated by Framer (2c4b293)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {y4mNWjTnt: {hover: true}};

const cycleOrder = ["y4mNWjTnt", "BgCWE6kYI"];

const serializationHash = "framer-jccC8"

const variantClassNames = {BgCWE6kYI: "framer-v-1ghm1g5", y4mNWjTnt: "framer-v-n9sb8j"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Closed: "y4mNWjTnt", Open: "BgCWE6kYI"}

const getProps = ({click, height, hover, id, width, ...props}) => { return {...props, PWSlWeqS6: hover ?? props.PWSlWeqS6, qZuVjJZe_: click ?? props.qZuVjJZe_, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "y4mNWjTnt"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;hover?: any;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PWSlWeqS6, qZuVjJZe_, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "y4mNWjTnt", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1ylpw9r = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (qZuVjJZe_) {const res = await qZuVjJZe_(...args);
if (res === false) return false;}
})

const onMouseEnter170i0h9 = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
if (PWSlWeqS6) {const res = await PWSlWeqS6(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-n9sb8j", className, classNames)} data-framer-name={"Closed"} data-highlight layoutDependency={layoutDependency} layoutId={"y4mNWjTnt"} onMouseEnter={onMouseEnter170i0h9} onTap={onTap1ylpw9r} ref={ref ?? ref1} style={{backgroundColor: "var(--token-4e605a0f-b3c1-42f0-bd07-ade601eb6c1f, rgb(44, 45, 47))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} {...addPropertyOverrides({"y4mNWjTnt-hover": {"data-framer-name": undefined}, BgCWE6kYI: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-l22333"} layoutDependency={layoutDependency} layoutId={"j2Ao2FwE4"} style={{backgroundColor: "var(--token-0c23e7ca-4b1c-4639-b5e7-c6d7ba50cdcc, rgb(255, 255, 255))", borderBottomLeftRadius: 0.5, borderBottomRightRadius: 0.5, borderTopLeftRadius: 0.5, borderTopRightRadius: 0.5, rotate: 0}} variants={{BgCWE6kYI: {rotate: -45}}}/><motion.div className={"framer-fno8up"} layoutDependency={layoutDependency} layoutId={"VLQYexGzN"} style={{backgroundColor: "var(--token-0c23e7ca-4b1c-4639-b5e7-c6d7ba50cdcc, rgb(255, 255, 255))", borderBottomLeftRadius: 0.5, borderBottomRightRadius: 0.5, borderTopLeftRadius: 0.5, borderTopRightRadius: 0.5, rotate: 0}} variants={{BgCWE6kYI: {rotate: 45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jccC8.framer-124h0ov, .framer-jccC8 .framer-124h0ov { display: block; }", ".framer-jccC8.framer-n9sb8j { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 4px; height: 50px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 50px; will-change: var(--framer-will-change-override, transform); }", ".framer-jccC8 .framer-l22333 { flex: none; height: 3px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; top: 18px; width: 24px; z-index: 1; }", ".framer-jccC8 .framer-fno8up { bottom: 19px; flex: none; height: 3px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; width: 24px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-jccC8.framer-n9sb8j { gap: 0px; } .framer-jccC8.framer-n9sb8j > * { margin: 0px; margin-bottom: calc(4px / 2); margin-top: calc(4px / 2); } .framer-jccC8.framer-n9sb8j > :first-child { margin-top: 0px; } .framer-jccC8.framer-n9sb8j > :last-child { margin-bottom: 0px; } }", ".framer-jccC8.framer-v-1ghm1g5 .framer-l22333 { order: 0; top: calc(50.00000000000002% - 3px / 2); }", ".framer-jccC8.framer-v-1ghm1g5 .framer-fno8up { bottom: unset; order: 1; top: calc(50.00000000000002% - 3px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 50
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"BgCWE6kYI":{"layout":["fixed","fixed"]},"Ef_wh2PqL":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"PWSlWeqS6":"hover","qZuVjJZe_":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerP9lJpHAwG: React.ComponentType<Props> = withCSS(Component, css, "framer-jccC8") as typeof Component;
export default FramerP9lJpHAwG;

FramerP9lJpHAwG.displayName = "Trigger";

FramerP9lJpHAwG.defaultProps = {height: 50, width: 50};

addPropertyControls(FramerP9lJpHAwG, {variant: {options: ["y4mNWjTnt", "BgCWE6kYI"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}, PWSlWeqS6: {title: "Hover", type: ControlType.EventHandler}, qZuVjJZe_: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerP9lJpHAwG, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})